.radio {
    display: flex;
    align-items: center;

    label {
        display: flex;
        margin: 0;

        &::before {
            content: '';

            width: 24px;
            height: 24px;
            border-radius: 3px;
            margin-right: 15px;
            border: 1px solid $color-border;
            margin-top: -4px;

            background-color: white;
        }
    }

    input {
        opacity: 0;
        position: absolute;
        width: 0;

        &:checked {
            & + label::before {
                @include background-cover('/svg/check.svg');
            }
        }
    }

    & + .radio {
        margin-top: 15px;
    }
}