.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  
  width: 100%;

  &.project_header {
    align-items: flex-start;

    a {
      font-family: $font;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid black;
      }
    }

    .settings_box {

      .settings {

        span {
          width: 7px;
          height: 7px;
          margin: 2px;
        }
      }

      .settings_menu {
        top: 45px;

        a {
          border: none !important;
          font-family: $font;

          &:hover {
            border: none !important;
          }
        }
      }
    }
  }

  h1 {
    margin: 0;
  }

  p {
    max-width: 100%;
    margin: 0;
  }

  label {
    font-family: $font;
    width: 150px;
    margin: 0 !important;
  }

  .description {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  form {

    .button {
      padding: 7px 27px;
      width: auto;
      margin-right: 15px;
    }

    p {
      margin-bottom: 1rem !important;
    }
  }
}
