.pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 60px;
    width: 100%;

    &__numbers {
        display: flex; 
    }

    &__item {
        @include flex-center;

        width: 35px;
        height: 35px;
        border-radius: 50px;

        background-color: white;

        color: $color-dark;
        font-size: 14px;
        font-family: $font;

        svg {
            width: 10px;
            margin: 0;
        }

        &.-active,
        &:hover {
            background-color: $color-1;
            color: white !important;

            svg {
                fill: white;
            }
        }

        &.-disabled {
            pointer-events: none;

            svg {
                opacity: .3;
            }
        }

        &:first-child {
            transform: rotate(180deg);
        }

        & + & {
            margin-left: 5px;
        }
    }

    &__text {
        margin-top: 20px;

        color: $black;
        font-size: 12px;
    }
}