.controls {
    display: flex;
    justify-content: space-between;
    position: relative;

    .button:hover {
      color: white !important;
    }

    &.right {
        justify-content: flex-end;
    }

    @media all and (max-width: 680px) {
      flex-direction: column;
      align-items: flex-start;

      .filter {
        margin-bottom: 20px;
      }
    }

    .filter {
        background-color: transparent;
        border-radius: 100px;
        border: 1px solid $color-dark;
        padding: 5px 37px 5px 50px;
        font-size: 18px;
        background-image: url('/svg/filter_icon.svg');
        background-position: 20px;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        width: 400px;

        &.active ~ .cross {
            display: block;
        }

        &::placeholder {
            color: $grey-500;
        }
    }

    .cross {
        position: absolute;
        left: 170px;
        top: 7px;
        font-family: "Proxima Nova W05 SemiBold", sans-serif;
        color: $grey-500;
        cursor: pointer;
        display: none;
    }
}