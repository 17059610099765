.input {
    display: block;

    transition: $transition;

    width: 100%;
    padding: 17px 20px;
    border: 1px solid $color-border;
    border-radius: 8px;
    height: 55px;
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;
    
    background-color: transparent;

    & + .error {
        margin-top: 5px;
    }

    & + .input {
        margin-top: 10px;
    }

    &:focus {
        border-color: $color-1;
        outline: none;
    }

    &::placeholder {
        color: $grey-600;
    }
}