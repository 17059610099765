.tools {

    &__card {
        @include card-base;

        .button {
            margin-top: 40px;
        }
    }

    &__link{
        display: inline-flex;
        align-items: center;

        border-radius: 7px;
        transform: translateX(-10px);
        width: 28px;
        height: 28px;
        margin-top: -3px;
        margin-bottom: -3px;

        background-color: $color-dark;
        float: right;

        svg {
            transform: translate(-1px);
            width: 15px;
            margin: auto;
            fill: white;
        }

        &:hover {
            background-color: $blue;
        }
    }
}
