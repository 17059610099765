.login{

	.container{
		margin-right:unset;
		margin-left:unset;
		max-width: unset;
        margin-bottom:0px;
        background-color: #fff;

        [class*=part]{
            margin-bottom:0px;
        }
	}

	&__auth{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
        padding-right: 60px;
        padding-bottom:60px;
        padding-top:60px;

        p{
            font-size:18px;

            img{
                width:15px;
                margin-left:8px;
            }
        }

        .button{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right:20px;
            padding-left:20px;
            width:50%;

            img{
                width:15px;
            }
        }

        .input{
            border:none;
            border-bottom:1px solid #000;
            border-radius:0;
            font-size:30px;
            padding-left:0;

            &::placeholder {
                font-size: 30px;
            }
        }
	}

	&__image{
		background-image: url('/images/login-screen.jpg');
		background-size: cover;
		height:100vh;
        padding:60px 60px 41px 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;

        h1{
            font-size:80px;
            color:#fff;
        }
	}
}