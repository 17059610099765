
.user {
  position: relative;
  
  width: 40px;
  height: 40px;
  margin-right: 140px;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  @include transition(150ms);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font;
  background-size: cover;
  background-position: center;

  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;
    border-radius: inherit;

    object-fit: cover;
  }

  a {
    & + a {
      padding-top: 0 !important;
    }
  }

  &.team {
    background-color: $color-dark;
    color: white;
    box-shadow: none;
    cursor: default;
    user-select: none;

    &:hover {
      box-shadow: inherit;
    }
  }

  p {
    margin: 0;
    font-family: $font;
    font-size: 16px;
  }

  &:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 1);
  }
}

.user_info {
  margin-top: 80px;
  margin-bottom: -20px;
  display: flex;

  div {
    width: 150px;
  }
}

.user-summary {
  margin-top: 100px;

  label {
    width: 150px;
    margin: 0 !important;
    display: inline-block;
    font-family: "Proxima N W01 Smbd";
  }
}

.hour_budget {
  display: flex;
  margin-top: 20px;
  width: 100%;

  input {
    margin-top: 0 !important;
  }
}

.user-hours-progress {
  margin-top: 30px;
}
