@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin transition($transition: $transition) {
    transition: $transition;
}

@mixin absolute-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

@mixin flex-wrapper (
    $space: 30px, 
    $space-l: 30px, 
    $space-m: 15px, 
    $space-s: 15px
) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    margin-bottom: -$space;

    @include media-l {
        margin-bottom: -$space-l;
    }

    @include media-m {
        margin-bottom: -$space-m;
    }

    @include media-s {
        margin-bottom: -$space-s;
    }

    > * {
        margin-top: 0;
        margin-bottom: $space;
        margin-right: $space;

        @include media-l {
            margin-bottom: $space-l;
            margin-right: $space-l;
        }

        @include media-m {
            margin-bottom: $space-m;
            margin-right: $space-m;
        }
        
        @include media-s {
            margin-bottom: $space-s;
            margin-right: $space-s;
        }
    }
}

// Backgrounds

@mixin background-cover($image : "") {
    @if ($image != "") {
      background-image: url($image);
    }
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  @mixin background-contain($image : "") {
    @if ($image != "") {
      background-image: url($image);
    }
  
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  // :before and :after
  
  @mixin clearfix {
    content: '';
    display: block;
    position: absolute;
  }
  
  // Transitions
  
  @mixin transition($duration : "") {
    @if ($duration != "") {
      transition: $duration;
    } @else {
      transition: $transition;
    }
  }
  
  // Fade into view
  
  @mixin fade-into-view($transform : "", $duration : "") {
    opacity: 0;
  
    @if ($duration != "") {
      @include transition($duration);
    } @else {
      @include transition($transition-slow);
    }
  
    @if ($transform != "") {
      transform: translateY($transform);
    } @else {
      transform: translateY(70px);
    }
  
    &.-into-view {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  // Visiblity (with transition)
  
  @mixin visibility($type: 'show') {
  
    @if $type == "hide" {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: $transition;
  
    } @else if $type == "show" {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
  
  // Text effects
  
  @mixin text-columns($columns, $gap) {
    column-count: $columns;
    column-gap: $gap;
  }
  
  @mixin text-ellipsis($width: '100%') {
    max-width: $width;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  // Media queries
  
  @mixin media-xs {
    @media (max-width: $media-xs) {
      @content;
    }
  }
  
  @mixin media-s {
    @media (max-width: $media-s) {
      @content;
    }
  }
  
  @mixin media-m {
    @media (max-width: $media-m) {
      @content;
    }
  }
  
  @mixin media-l {
    @media (max-width: $media-l) {
      @content;
    }
  }
  
  @mixin media-xl {
    @media (max-width: $media-xl) {
      @content;
    }
  }
  
  @mixin media-break {
    @media (max-width: #{$max-width}) {
      @content;
    }
  }
  
  // Object-fit
  
  @mixin object-contain($position: center, $width: 100%, $height: 100%) {
    width: $width;
    height: $height;
    object-fit: contain;
    object-position: $position;
  }
  
  @mixin object-cover($position: center, $width: 100%, $height: 100%) {
    width: $width;
    height: $height;
    object-fit: cover;
    object-position: $position;
  }
  
  // Color effects
  
  @mixin color-multiply($color: $color-1) {
    background-color: $color;
    background-blend-mode: multiply;
  }
  
  @mixin blur($blur, $brightness, $opacity: 0, $color: 255) {
    background-color: rgba($color, $color, $color, $opacity);
    backdrop-filter: blur($blur) brightness($brightness);
  }
  
  // Aspect-ratio
  
  @mixin aspect-ratio($percent: 100%, $side: 'top') {
    height: 0;
    width: 100%;
    padding-#{$side}: $percent;
  }
  
  // Parts
  
  @mixin part {
    [class*="part"] {
      @content;
    }
  }
  
  @function grid-part ($size: 1, $model: $grid-model, $gutter: $grid-gutter) {
    @if $size == 0 {
      @return 0px;
    }
    @return (calc(((#{$max-width} - (#{$gutter} * (#{$model} - 1))) / #{$model}) * #{$size} + (#{$gutter} * (#{$size} - 1))));
  }
  
  // Colors
  @mixin background-colors {
    &.-color-1 {
      background-color: $color-1;
    }
  
    &.-color-2 {
      background-color: $color-2;
    }
  
    &.-color-3 {
      background-color: $color-3;
    }
  
    &.-white {
      background-color: white;
    }
  
    &.-black {
      background-color: $color-black;
    }
  }
  
  // Absolute position
  
  @mixin absolute-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    width: 100%;
    height: 100%;
  }
  
  
  @mixin absolute-contain {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  // Lege before, after
  
  @mixin before {
    &::before {
      content: '';
      @content;
    }
  }
  
  @mixin before-absolute {
    position: relative;
  
    &::before {
      content: '';
  
      position: absolute;
      top: 0;
      left: 0;
  
      @content;
    }
  }
  
  @mixin after {
    &::after {
      content: '';
      @content;
    }
  }
  
  @mixin after-absolute {
    position: relative;
  
    &::after {
      content: '';
  
      position: absolute;
      top: 0;
      left: 0;
  
      @content;
    }
  }

@mixin card-base {
  padding: 40px;
	border-radius: 20px;
	border: 1px solid $color-border;

	background-color: white;

	animation: loadBlock 500ms;

  @include media-s {
    padding: 30px;
  }
}