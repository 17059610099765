table {
	width: 100%;
	margin: 0;
	font-size: 18px;
	border-collapse: separate;
	border-spacing: 0 10px;

	th {
		padding: 40px 0 10px 30px;
		max-width: 200px;
		
		font-family: $font;
		text-align: left;
		white-space: nowrap;

		@include media-s {
			padding: 20px 0 10px 20px;
			font-size: 12px;
		}

		&:last-child {
			padding-right: 30px;
		}
	}

	td {
		vertical-align: middle;
		padding: 18px 0 18px 30px;
		max-width: 200px;
		margin-top: 10px;
		border-top: 1px solid $color-border;
		border-bottom: 1px solid $color-border;

		transition: border-color $transition;
		
		background-color: white;
		cursor: pointer;

		@include media-l {
			font-size: 12px;
			padding: 10px 0 10px 20px;
		}

		&:first-child {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			border-left: 1px solid $color-border;

			@include media-s {
				white-space: nowrap;
			}
		}
		
		&:last-child {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			border-right: 1px solid $color-border;
		}

		&.center {
			padding-top: 10px;
			padding-bottom: 10px;
			max-width: 220px;
		}

		&:last-child {
			padding-right: 30px;
		}

		&.progress_box {
			padding-top: 21px;
		}

		pre {
			margin: 0;
			font-family: $font;
			white-space: pre-wrap;

			@include media-l {
				font-size: 12px;
			}
		}
	}

	tr {
		border-bottom: 2px solid $grey;
		max-width: 200px;
		
		@include transition(100ms);

		&:hover {
			td {
				border-color: $grey-500;
			}
		}

		&:nth-child(1) {
			th {
				padding-top: 0;
			}
		}

		&.active {
			display: table-row !important;
		}

		&:hover {
			background-color: $grey;
		}
	}
}

.table_box {
	
	@include media-l {
		overflow: scroll;

		width: calc(100% + 50px);
		padding-left: 25px;
		padding-right: 25px;
		margin-left: -25px;
		margin-right: -#{$space-side};
	
		@include hide-scrollbar;
	}
}