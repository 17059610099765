.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    pointer-events: none;
    opacity: 0;
    transition: opacity $transition;

    padding-top: 25vh;
    width: 100%;
    height: 100%;

    background-color: transparentize($color-dark, 0.5);

    &__inner {
        border-radius: 20px;
        border: 1px solid $color-border;
        padding: 40px;
        width: 480px;
        max-width: calc(100% - 40px);
        margin: auto;

        background-color: white;
    }

    &-dialog {
        padding: 40px;
        border: 1px solid $color-border;
        max-width: 500px;
        margin: auto;
        border-radius: 10px;
        background-color: white;
    }

    &.-active {
        pointer-events: auto;
        opacity: 1;

        .modal__inner {
            animation: loadBlock 400ms;
        }
    }
}