@font-face {
    font-family: PPNeueMontreal;
    src: url("Fonts/PPNeueMontreal-Regular-1.otf") format("opentype");
}

// Breakpoints
$media-xs: 320px;
$media-s: 576px;
$media-m: 768px;
$media-l: 992px;
$media-xl: 1200px;

// Colors
$grey: #f4f5f5;
$grey-500: #bdbdbd;
$grey-600: #909090;
$grey-900: #333333;

$black: #000000;
$red: #E90000;
$green: #00B414;
$second_green: #1EC42D;
$blue: #2C75FF;

$color-error: $red;
$color-error-dark: #D10000;

$color-1: #383838;
$color-1-hover: #383838;

$color-2: #878CA0;
$color-2-hover: #878CA0;

$color-light: #F4F5F9;
$color-border: #EAEAEA;
$color-dark: #272727;

// Typography
$font: PPNeueMontreal, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Transition
$transition: 147ms ease-in-out;

// Grid
$grid-model: 12;
$grid-gutter: 40px;
$grid-model-xl: 12;
$grid-gutter-xl: 16px;
$grid-model-l: 12;
$grid-gutter-l: 16px;
$grid-model-m: 12;
$grid-gutter-m: 16px;
$grid-model-s: 12;
$grid-gutter-s: 16px;
$grid-model-xs: 12;
$grid-gutter-xs: 16px;


// Space
$space-standard: 100px;
$space-standard-l: 100px;
$space-standard-m: 80px;
$space-standard-s: 80px;
$space-standard-xs: 80px;

$space-small: 80px;
$space-small-l: 80px;
$space-small-m: 60px;
$space-small-s: 60px;
$space-small-xs: 40px;

$space-large: 190px;
$space-large-l: 135px;
$space-large-m: 135px;
$space-large-s: 115px;
$space-large-xs: 115px;

$space-part: 60px;
$space-part-l: 60px;
$space-part-m: 60px;
$space-part-s: 40px;
$space-part-xs: 40px;

$space-side: 0;
$space-side-m: 0;

$max-width: 1250px;