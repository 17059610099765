header {

  .v-select {
    height: 40px;
    width: 200px;

    .vs__dropdown-toggle {
      border: 1px solid white!important;
      padding: 7px 20px 10px;
      height: 40px;
      border-radius: 20px;
      transition: 147ms ease-in-out;

      input[type="search"]::-webkit-search-decoration {
        width: 0!important;
        position: absolute;
      }

      span, div, div span, input {
        padding: 0!important;
        margin: 0!important;
      }

      .vs__selected-options {
        padding: 0;
        display: flex;
        align-items: center;
        max-width: calc(100% - 15px);
        overflow: hidden;
        margin-right: 15px!important;

        .vs__selected {
          padding-left: 0;
          font-size: 15px;
          color: white;
          white-space: nowrap;
          height: 100%;
        }
      }

      .vs__actions {
        padding-right: 0;
        background-image: url(/images/arrow-down-white.svg);
        background-position: right center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 15px;

        svg {
          display: none;
        }

        .vs__clear {
          pointer-events: none;
          display: none;
        }
      }
    }

    .vs__search {
      color: white!important;
      font-size: 15px!important;
      padding-left: 0!important;
      margin: 0;

      input {
        color: white!important;
      }
    }

    .vs__dropdown-menu {
      padding: 0;
      box-shadow: none;
      max-height: 500px;
      border-radius: 0;
      overflow-x: hidden;

      li {
        padding: 10px 15px;
        font-size: 15px;
        border-top: 1px solid white;
        background-color: $color-dark;
        color: white;
        white-space: normal;

        a {
          white-space: normal;
        }

        &:hover,
        &:focus,
        &:active,
        &:focus-within,
        &.vs__dropdown-option--highlight {
          background-color: #404040!important;
        }
      }
    }
  }
}

main {

  .v-select {
    height: 52px;
    width: 704px;
    border: 1px solid black;

    &.large {
      margin-top: 20px;

      .vs__dropdown-toggle,
      .vs__dropdown-toggl
      {
        width: 100%!important;
      }
    }

    .vs__dropdown-toggle {
      border: 0;
      padding: 10px 20px;
      border-radius: 0;
      height: 52px;
      width: 295px;

      input[type="search"]::-webkit-search-decoration {
        width: 0!important;
        position: absolute;
      }

      span, div, div span, input {
        padding: 0!important;
        margin: 0!important;
      }

      input {
        width: 100%;
        height: 100%;
        border: none;
      }

      .vs__selected-options {
        padding: 0;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 0!important;

        .vs__selected {
          padding-left: 0!important;
          font-size: 15px;
          color: black;
          white-space: nowrap;
          height: 100%;
          width: 215px!important;
          margin-right: 20px!important;
          overflow: hidden;
        }
      }

      .vs__actions {
        padding-right: 0;
        background-image: url(/svg/arrow_down_black.svg);
        background-position: right center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 15px;

        svg {
          display: none;
        }

        .vs__clear {
          pointer-events: none;
          display: none;
        }
      }
    }

    .vs__search {
      color: black;
      font-size: 15px!important;
      padding-left: 0!important;
      margin: 0;
    }

    .vs__dropdown-menu {
      padding: 0;
      box-shadow: none;
      max-height: 400px;
      border-radius: 0;
      border: 1px solid black;
      width: calc(100% + 2px);
      margin-left: -1px;

      li {
        padding: 16px 20px;
        font-size: 15px;
        border-top: 1px solid black;
        background-color: white;
        color: black;
        white-space: normal;

        a {
          white-space: normal;
        }

        &:hover,
        &:focus,
        &:active,
        &:focus-within,
        &.vs__dropdown-option--highlight {
          background-color: #E5E5E5!important;
          color: black!important;
        }
      }
    }
  }

}