.filter {
    display: flex;

    &__arrow {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        border: 1px solid $color-border;

        transition: border $transition;

        @include background-contain("/images/arrow_left_blue_small.svg");
        
        background-color: white;
        background-size: 18%;
        cursor: pointer;

        &:hover {
            border-color: $grey-500;
        }

        &.-right {
            transform: rotate(180deg);
        }
    }

    &__week {
        @include flex-center;

        flex-grow: 1;
        
        padding: 10px 10px;
        margin: 0 10px;

        cursor: pointer;        
        text-align: center;

    }

    & + * {
        margin-top: 30px;
    }
}