.dropdown {
    position: relative;
    width: 220px;

    &__input {
        padding: 13px 20px;
        border-radius: 8px;
        width: 100%;
        border: none;

        cursor: pointer;

        font-size: 14px;
    }
    
    &__menu {
        top: 35px;
        position: absolute;
        overflow-y: auto;
        
        display: flex;
        flex-direction: column;
        
        max-height: 50vh;
        border-radius: 0 0 7px 7px;
        width: 100%;
        
        @include hide-scrollbar;
        
        background-color: white;
    }
    
    &__item {
        display: block;
        padding: 7px 20px;
        font-size: 14px;
        line-height: 1.2;

        cursor: pointer;

        color: $black;

        &:hover:not(.-empty),
        &:focus:not(.-empty) {
            color: $color-2 !important; 
        }

        &:first-child {
            padding-top: 15px;
        }

        &:last-child {
            padding-bottom: 15px;
        }
    }

    &.-active {
        z-index: 9;
    }
}

form {

    .dropdown {
        width: calc(100% - 90px);
        
        * {
            font-size: 15px;
        }

        .dropdown__input {
            background-image: url("/svg/arrow_down_black.svg");
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: right 20px center;

        }

        .dropdown__menu {
            top: 55px;
            border-left: 1px solid $color-border;
            border-right: 1px solid $color-border;
            border-bottom: 1px solid $color-border;
        }
    }
}

header {
    .dropdown {
        &__menu {
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
        }
    }
}