
.welcome {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .or {
    color: $color-dark !important;
    font-family: $font;
    margin-top: 30px;
    @include transition;
    margin-bottom: 90px;

    img {
      display: inline-block;
      width: 25px;
      margin-bottom: 3px;
      padding-left: 5px;
      @include transition(100ms);
    }

    &:hover img {
      margin-left: 5px;
      margin-right: -5px;
    }

    span {
      width: 10px;
    }
  }

  .home_choice {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    width: 560px;

    a {
      height: 165px;
      width: 250px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
      margin: 15px;
      padding: 24px;
      font-size: 18px;
      font-family: $font;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      border-radius: 8px;
      transition: 0.2s;

      img {
        width: 40px;

        &.team {
          width: 55px;
        }
      }

      h6 {
        margin-top: 25px;
      }

      &:hover {
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
      }
    }
  }
}
