@charset "UTF-8";

$grid-spacing: 45px !default;
$grid-model: 12 !default;

@mixin grid-item($size: 1, $spacing: $grid-spacing, $model: $grid-model) {
    margin-right: $spacing;

    @if ($spacing == 0) {
        width: calc((((100% - .5px) / #{$model}) * #{$size}));
    } @else {
        width: calc(((((100% - .5px) - (#{$spacing} * (#{$model} - 1))) / #{$model}) * #{$size}) + (#{$spacing} * (#{$size} - 1)));
    }
}

@mixin grid($model: $grid-model, $spacing: $grid-spacing) {
    @for $i from 1 through $model {
        &--#{$i} {
            @extend .grid__item;
            @include grid-item($i, $spacing);
        }
    }
}

.grid {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .grid__item {
        position: relative;
        display: inline-block;

        box-sizing: border-box;

        &:last-child {
            margin-right: 0;
        }

        @include grid;
    }
}