.week-times {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        
        &:not(:last-child) {
            padding-right: 15px;
        }

        @include media-s {
            width: 33%;
            margin-bottom: 20px;
            padding-right: 0 !important;
            padding-left: 0 !important;
            border-left: 1px solid $color-border;
            border-right: 1px solid $color-border;
        }

        & + & {
            padding-left: 20px;
            border-left: 1px solid $color-border;
        }
    }

    &__day {
        display: block;
        margin-bottom: 10px;
        font-weight: 600;

        &.--green{
            color:$second_green;
        }
    }

    &__time {
        &.-color-1 {
            color: $blue;
        }
    }

    * {
        text-align: center;
    }
}