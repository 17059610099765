.avatar {
    display: flex;
    align-items: center;
    
    &__image {
        position: relative;
        
        display: flex;
        align-items: center;
        justify-content: center;
        
        width: 30px;
        height: 30px;
        border-radius: 7px;
        display: inline-block;
        margin-right: 15px;

        background-color: $color-dark;
        
        font-family: $font;
        font-size: 13px;
        font-weight: 800;
        line-height: 2.4;
        color: white;
        text-align: center;

        img {
            @include absolute-cover;
    
            border-radius: inherit;
            object-fit: cover;
        }
    }
}