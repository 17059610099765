@mixin grid-basic-styles {
    position: relative;
  
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  @function part-width ($size: 1, $model: $grid-model, $gutter: $grid-gutter) {
    @return (calc(((100% - (#{$gutter} * (#{$model} - 1))) / #{$model}) * #{$size} + (#{$gutter} * (#{$size} - 1))));
  }
  
  @mixin grid {
    @include grid-basic-styles;
  
    @for $i from 1 through $grid-model {
      .part-#{$i}:not(.no-gutter) {
        width: part-width($i, $grid-model, $grid-gutter);
      }
      .part-#{$i}.no-gutter {
        width: part-width($i, $grid-model, 0px);
      }
    }
  
    @media (max-width: $media-xl) {
      @for $i from 1 through $grid-model-xl {
        .part-xl-#{$i}:not(.no-gutter) {
          width: part-width($i, $grid-model-xl, $grid-gutter-xl);
        }
        .part-xl-#{$i}.no-gutter {
          width: part-width($i, $grid-model-xl, 0px);
        }
      }
    }
  
    @media (max-width: $media-l) {
      @for $i from 1 through $grid-model-l {
        .part-l-#{$i}:not(.no-gutter) {
          width: part-width($i, $grid-model-l, $grid-gutter-l);
        }
        .part-l-#{$i}.no-gutter {
          width: part-width($i, $grid-model-l, 0px);
        }
      }
    }
  
    @media (max-width: $media-m) {
      @for $i from 1 through $grid-model-m {
        .part-m-#{$i}:not(.no-gutter) {
          width: part-width($i, $grid-model-m, $grid-gutter-m);
        }
        .part-m-#{$i}.no-gutter {
          width: part-width($i, $grid-model-m, 0px);
        }
      }
    }
  
    @media (max-width: $media-s) {
      @for $i from 1 through $grid-model-s {
        .part-s-#{$i}:not(.no-gutter) {
          width: part-width($i, $grid-model-s, $grid-gutter-s);
        }
        .part-s-#{$i}.no-gutter {
          width: part-width($i, $grid-model-s, 0px);
        }
      }
    }
  
    @media (max-width: $media-xs) {
      @for $i from 1 through $grid-model-xs {
        .part-xs-#{$i}:not(.no-gutter) {
          width: part-width($i, $grid-model-xs, $grid-gutter-xs);
        }
        .part-xs-#{$i}.no-gutter {
          width: part-width($i, $grid-model-xs, 0px);
        }
      }
    }
  
    @for $i from 0 through $grid-model {
  
      .part-top-#{$i} {
        margin-top: grid-part($i);
      }
  
      .part-left-#{$i} {
        margin-left: grid-part($i);
      }
  
      .part-right-#{$i} {
        margin-right: grid-part($i);
      }
  
      .part-bottom-#{$i} {
        margin-bottom: calc(#{grid-part($i)} + #{$space-part});
      }
    }
  
    @include media-l {
  
      @for $i from 0 through $grid-model {
        .part-top-l-#{$i} {
          margin-top: grid-part($i);
        }
  
        .part-left-l-#{$i} {
          margin-left: grid-part($i);
        }
  
        .part-right-l-#{$i} {
          margin-right: grid-part($i);
        }
  
        .part-bottom-l-#{$i} {
          margin-bottom: calc(#{grid-part($i)} + #{$space-part-l});
        }
      }
    }
  
    @include media-m {
  
      @for $i from 0 through $grid-model {
        .part-top-m-#{$i} {
          margin-top: grid-part($i);
        }
  
        .part-left-m-#{$i} {
          margin-left: grid-part($i);
        }
  
        .part-right-m-#{$i} {
          margin-right: grid-part($i);
        }
  
        .part-bottom-m-#{$i} {
          margin-bottom: calc(#{grid-part($i)} + #{$space-part-m});
        }
      }
    }
  
    @include media-s {
  
      @for $i from 0 through $grid-model {
        .part-top-s-#{$i} {
          margin-top: grid-part($i);
        }
  
        .part-left-s-#{$i} {
          margin-left: grid-part($i);
        }
  
        .part-right-s-#{$i} {
          margin-right: grid-part($i);
        }
  
        .part-bottom-s-#{$i} {
          margin-bottom: calc(#{grid-part($i)} + #{$space-part-s});
        }
      }
    }
  
    @include media-xs {
  
      @for $i from 0 through $grid-model {
        .part-top-xs-#{$i} {
          margin-top: grid-part($i);
        }
  
        .part-left-xs-#{$i} {
          margin-left: grid-part($i);
        }
  
        .part-right-xs-#{$i} {
          margin-right: grid-part($i);
        }
  
        .part-bottom-xs-#{$i} {
          margin-bottom: calc(#{grid-part($i)} + #{$space-part-xs});
        }
      }
    }
  
    .part-xs-0 {
      @media (max-width: $media-xs) {
        display: none;
      }
    }
  
    .part-s-0 {
      @media (max-width: $media-s) {
        display: none;
      }
    }
  
    .part-m-0 {
      @media (max-width: $media-m) {
        display: none;
      }
    }
  
    .part-l-0 {
      @media (max-width: $media-l) {
        display: none;
      }
    }
  
    .part-xl-0 {
      @media (max-width: $media-xl) {
        display: none;
      }
    }
  
    [class*="part"] {
  
      &.-justify-start {
        display: flex;
        justify-content: flex-start;
      }
  
      &.-justify-center {
        display: flex;
        justify-content: center;
      }
  
      &.-justify-end {
        display: flex;
        justify-content: flex-end;
      }
  
      &.-align-start {
        display: flex;
        align-items: flex-start;
      }
  
      &.-align-center {
        display: flex;
        align-items: center;
      }
  
      &.-align-end {
        display: flex;
        align-items: flex-end;
      }
  
      &.-text-left {
        text-align: left;
      }
  
      &.-text-center {
        text-align: center;
      }
  
      &.-text-right {
        text-align: right;
      }
    }
}