@keyframes loadBlock {
    0%, 10% {
      transform: scale(.9);
      opacity: 0;
    }
}
  
@keyframes scaleBlock {
    0% {
      transform: scale(.85);
      opacity: 0;
    }
  
    10% {
      transform: scale(.85);
      opacity: 0;
    }
  
    70% {
      transform: scale(1.03);
    }
  
    100% {
      transform: scale(1);
    }
}
  
@keyframes wave {
    0%, 35%, 100% {
      transform: rotate( 0.0deg)
    }
    10% {
      transform: rotate(15.0deg)
    }
    15% {
      transform: rotate(-9.0deg)
    }
    20% {
      transform: rotate(15.0deg)
    }
    25% {
      transform: rotate(-5.0deg)
    }
    30% {
      transform: rotate(11.0deg)
    }
}
  
@keyframes bell {
    0%,
    50%,
    100% {
      transform: rotate(0deg);
    }
    60%,
    80% {
      transform: rotate(10deg);
    }
    70%,
    90% {
      transform: rotate(-10deg);
    }
}
  
@keyframes scale {
    20% {
      transform: scale(0);
    }
  
    100% {
      transform: scale(1);
    }
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}