.card {
	position: relative;
	display: block;
	
	@include card-base;

	&__row,
	&__link {
		display: flex;
		padding-bottom: 10px;

		* {
			color: $color-1;
		}

		label {
			width: 45%;
		}

		& + .card__row,
		& + .card__link {
			padding-top: 10px;
			border-top: 1px solid $color-border;
		}

		&.-bold {
			* {
				font-weight: 700;
			}
		}

		&.-between {
			justify-content: space-between;

			> *:nth-child(1) {
				width: 80%;
			}

			> *:last-child {
				text-align: right;
				white-space: nowrap;
			}
		}

		&:last-child {
			padding-bottom: 0;
		}
	}

	&__link {
		justify-content: space-between;
		cursor: pointer;

		label {
			transition: $transition;
			width: 80%;
			cursor: pointer;
		}

		svg {
			transition: 150ms;
			width: 12px;
			margin-left: 10px;

		}
		
		&.-justify-start {
			justify-content: flex-start;
			
			color: $blue;
			
			svg {
				fill: $blue !important;
			}
		}

		&:hover {
			* {
				color: $color-2 !important;
			}
			svg {
				transform: translateX(5px);
			}
		}
	}

	&__icon {
		@include flex-center;
		position: absolute;
		right: 60px;
		top: 0;
		bottom: 0;

		margin: auto;
		font-size: 110px;
	}

	&.-dark {
		background-color: $black;
		border-color: $color-dark;

		* {
			color: white;
		}
	}

	&.-color-2 {
		background-color: $color-1;
		border-color: $color-1;

		.card__row,
		.card__link {
			border-color: transparentize($color: white, $amount: .7);
		}

		* {
			color: white;
			fill: white;
		}
	}

	&.-row {
		display: flex;

		> * {
			width: 50%;
		}
	}

	& + &,
	& + .button {
		margin-top: 40px;

		@include media-s {
			margin-top: 20px;
		}
	}

	p {
		& + .card__row {
			margin-top: 20px;
		}
	}

	&__weekcircle{
		display:flex;
		align-items: center;
		margin-top: 22px;

		span{
			width:6px;
			height:6px;
			display:block;
			background-color:$second_green;
			border-radius:50%;
			margin-right:9px;
		}
	}
}