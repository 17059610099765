.button {
	@include transition;

	display: inline-flex;
	align-items: center;
    
    border: none;
	padding: 16px 34px 16px 34px;
    border-radius: 10px;
    
    background-color: black;
	cursor: pointer;
    
	font-family: $font;
	font-size: 16px;
	color: white;

	span {
		font-size: inherit;
		color: inherit;
	}

	&__counter {
		display: inline-flex;
		justify-content: center;
		align-items: center;

		padding-top: 1px;
		margin-left: 10px;
		border-radius: 10px;
		width: 17px;
		min-width: 17px;
		height: 17px;
		transform: translateY(-1px);

		background-color: white;

		text-align: center;
		font-size: 12px !important;
		color: $color-dark !important;
	}

	i {
		color: inherit;
	}

	&.-color-1 {
		background-color: $color-2;
	}
	
	&.-new {
		position: fixed;
		bottom: 50px;
		right: calc(( 100vw - #{$max-width} ) / 2 + 19px);
		z-index: 1;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 50px;
		height: 50px;
		padding: 0;
		border-radius: 100%;
		padding: 0 0 5px;
		
		font-size: 28px;
		background-color: $blue;

		@include media-s {
			right: 30px;
			bottom: 30px;
		}

		&.-not-fixed {
			position: static;
			margin-right: 10px;
		}
	}

	&.-remove,
	&.-red {
		background-color: $color-error;
		color: white;

		svg path {
			fill: white;
		}

		&:hover {
			background-color: $color-error-dark;
		}
	}

	&.-transparent {
		padding: 0;
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}

		&.-red {
			color: $color-error;
		}
	}

	&.-light {
		background-color: $color-light;
		color: black;

		&:hover {
			background-color: $color-1-hover;
		}
	}

	&.-bordered {
		border: 2px solid $color-border;
		padding: 12px 24px;
		background-color: transparent;
		color: $color-2;

		&:hover {
			background-color: $color-2;
			border-color: $color-2;
			color: white;
		}
	}

	&.-square {
		@include flex-center;
		
		padding: 0;
		width: 46px;
		height: 46px;

		font-size: 24px;
		font-weight: 800;

		img, svg, i {
			width: 70%;
			height: 35%;
			object-fit: contain;

			&::before {
				display: block;
				margin-left: 7px;
				margin-top: 0px;
				font-size: 16px;
			}
		}
	}

	&.-white {
		background-color: white;
		color: $color-dark !important;
	}

	&.-uppercase {
		padding-top: 12px !important;
		padding-bottom: 12px !important;
		font-size: 14px;
	}

	&.-small {
		border-radius: 8px;
		padding: 10px 15px;
		font-size: 14px;
	}

	&.-right {
		display: table;
		margin-left: auto;
	}

	&:hover {
		color: white !important;
		background-color: $color-1-hover;
	}
}


.buttons {
	@include flex-wrapper(10px);

	margin-top: 0;

	> * {
		width: unset;

		&:last-child {
			margin-right: 0;
		}
	}
}