@charset "UTF-8";

$navigation-item-spacing: 20px !default;

.navigation {
    display: flex;

    .navigation__item {
        display: inline-block;
        margin: 0 $navigation-item-spacing;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    &--vertical {
        @extend .navigation;

        flex-direction: column;

        .navigation__item {
            margin: $navigation-item-spacing 0;

            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}