* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
	
	font-family: $font;
	font-size: 15px;
	color: $color-1;
	font-weight: 400;
}

body {
	background: $color-light; 

	main {
		min-height: 100vh;
		width: 100%;
		padding: 200px 25px 140px 25px; 
		max-width: $max-width;
		margin-left: auto;
		margin-right: auto;
	
		@media all and (max-width: 680px) {
		  padding: 160px 25px 140px 25px;
		}

		.row {
			display: flex;

			.dropdown {
				
				&__input {
					border: 1px solid $color-border;
					min-height: 55px;
				}
			}

			& > * {
				margin-left: 15px;
			}
		}
	}
}