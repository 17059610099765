
.v--box {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9;
}

.v--model {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 450px;
  height: auto;
  margin: auto;
  left: 0;
  right: 0;
  box-shadow: none;
  padding: 55px 50px 45px 40px;
  background-color: white;
  border-radius: 5px;


  p {
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
  }

  form {
    width: unset;
    display: inline-block;
  }

  .controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 0 !important;
    align-items: center;

    .button {
      height: 45px;
    }
  }
}
