.hamburger {
  display: none;
  flex-wrap: wrap;
  align-items: center;
  height: 27px;
  width: 30px;

  span {
    display: inline-block;
    background-color: white;
    height: 2px;
    width: 30px;
    @include transition;
  }

  &.-active {
    align-items: center;

    span:nth-child(1) {
      transform: rotate(45deg);
      margin-top: 10px;
      margin-bottom: -7px;
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
      margin-bottom: 10px;
      margin-top: -7px;
    }
  }

  @media all and (max-width: 680px) {
    display: flex;
  }
}

.hamburger_menu {
  width: 100%;
  height: 100vh;
  left: -100%;
  background-color: #f8fafc;
  position: fixed;
  top: 0;
  z-index: 9;
  padding: 30px;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include transition;

  &.active {
    left: 0;
  }

  a {
    margin-bottom: 10px;
    font-size: 20px;
    border-bottom: 2px solid transparent;

    &:hover, &.active {
      border-bottom: 2px solid $black;
      color: $black;
    }
  }

  @media all and (min-width: 680px) {
    display: none;
  }
}
