.auth {
	
	&__container {
		@include flex-center;
	}

	&__box {
		@include card-base;
		
		margin: auto;
		padding: 50px;
		max-width: 400px;
	}

	&__link {
		margin-left: 22px;
		color: #909090;
		font-size: 11px;
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		width: 100%;
		margin-top: 50px;
	}

	&__main {
		padding-top: 25vh;
	}
}