.progress {
  overflow: hidden;

  border-radius: 200px;
  height: 6px;
  width: 130px;

  background-color: $grey;

  &_box {
    &.-row {
      display: flex;
      align-items: center;
    }
  }

  &__percentage {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 600;
  }

  .filling {
    width: 0;
    max-width: 100%;
    height: 100%;
    background-color: $green;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;

    &.warning {
      background-color: #FFB100;
    }

    &.full {
      background-color: $red;
      border-radius: 20px;
    }
  }

  &.max {
    position: relative;
    overflow: visible;

    &::after {
      // opacity: 0;
      content: '';
      width: 20px;
      height: 6px;
      position: absolute;
      right: 0;
      background-color: $red;
      border-radius: 20px;
      background-position: 6px;
      top: 0;
      background-image: url('/svg/alert.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
