.submenu {
    position: relative;

    & > a,
    &__title {
      padding: 20px 0;

      color: white;
      font-weight: 500;

      &::after {
        content: '';
        
        display: inline-block;
        
        transform: rotate(180deg);
        
        width: 10px;
        height: 10px;
        margin-left: 10px;
        
        background-image: url("/svg/arrow-up-white.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }

    &:hover {

      a::after {
        transform: rotate(0deg);
      }

      .sublist {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }
}

.sublist {
  position: absolute;
  background-color: $black;
  top: 40px;
  left: -20px;
  border-radius: 7px;
  padding: 15px 0;
  opacity: 0;
  visibility: hidden;

  a  {
    display: block;
    white-space: nowrap;
    padding: 5px 30px 5px 25px;
    margin: 0;
    color: white;

    &:hover {
      opacity: .7;
      color: white !important;
    }
  }
}