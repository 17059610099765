.annuleren, .annuleren_remove {
  color: $grey-500;
  cursor: pointer;
  @include transition;
  font-family: $font;
  display: inline-block;
  margin-left: 10px;

  &:hover {
    color: $grey-900;
  }
}
