header {
	position: fixed;
	top: 10px;
	left: 0;
	right: 0;
	z-index: 99;

	display: flex;
	justify-content: space-between;
	align-items: center;

	max-width: calc(100% - 50px);
	width: calc(#{$max-width} - 50px);
	height: 80px;
	background-color: $black;
	border-radius: 10px;
	margin: auto;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	padding: 0 20px;

	@media all and(max-width: 1300px) {
		padding: 0 50px;
	}

	@media all and(max-width: 680px) {
		flex-direction: row-reverse;
		padding: 0 20px;
	}

	.user {
		position: relative;
		background-color: black;

		&::after {
		transform: rotate(180deg);
		content: '';
		width: 10px;
		height: 10px;
		background-image: url("/svg/arrow-up-white.svg");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		right: -20px;
		}

		@media all and (max-width: 680px) {
		display: none;
		}

		.menu {
			background-color: $black;
			position: absolute;
			top: 50px;
			left: -20px;
			white-space: nowrap;
			border-radius: 0 0 7px 7px;
			display: none;
			user-select: none;
			color: white;
			min-width: 140px;
			padding-top: 10px;

		p {
			cursor: text;
			user-select: text;
		}

		a, p {
			padding: 15px 20px;
			display: block;
			font-size: 14px;
			color: white;
		}

		a:hover {
			opacity: 0.6;
		}

		&.active {
			display: block;
		}
		}
	}

	nav {
		height: 100%;
		display: flex;
		align-items: center;

		@media all and (max-width: 680px) {
		display: none;
		}

		a {
			padding: 38px 5px 38px 5px;
			margin: 0 15px 0 0;

			color: white;

			@include transition(147ms);

			&:hover, &.active {
				opacity: .5;
				color: white !important;
			}
		}
	}
}