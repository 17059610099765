h1 {
    font-size: 28px;
    line-height: 1.23;
    color: $black;

    @include media-s {
        font-size: 24px !important;
    }

    &.-weight-light {
        // font-weight: 500;
        font-size: 18px;
    }

	& + * {
		margin-top: 20px;
	}
}

h2 {
    color: $black;
}

h3 {
    font-size: 24px;
    line-height: 1.23;
    color: $black;

    & + * {
        margin-top: 20px;
    }
}

h4 {
    font-size: 20px;
    color: $black;
  
    @media all and (max-width: 800px){
      font-size: 18px;
    }
}

h5 {
    font-size: 20px;
    color: $black;

    & + * {
        margin-top: 30px;
    }
}
  
h6 {
    font-size: 18px;
    color: $black;
}
  
a {
    text-decoration: none!important;
    transition:150ms;
  
    &:hover{
		color:inherit!important;
    }
}

.error {
    display: block;
    margin-top: 10px;

    color: $color-error;
    font-size: 12px;

    & + * {
        margin-top: 15px;
    }
}

b, strong, .t-semibold {
    font-weight: 400;
}

.wide-span {
    display: inline-block;
    min-width: 130px;
    font-weight: 400;
}

.-color-light {
    color: $color-2;
}

i {
    font-size: inherit;
}

::selection {
    color: white;
    background: $color-dark;
}

small {
    display: block;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    color: $color-2;
    font-size: 12px;
}