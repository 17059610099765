.popup {
    position: relative;
    z-index: 1;

    display: flex;

    &__inner {
        position: absolute;
        top: 70px;
        right: 0;
        
        display: none;
        
        padding: 30px;
        border: 1px solid $color-border;
        border-radius: 15px;
        width: 280px;

        box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
        
        background-color: white;
        
        * {
            font-size: 14px;
        }

        label {
            margin-left: 10px !important;
            padding-left: 7px !important;
            background-color: white !important;
        }

        input, 
        select {
            padding: 12px 15px !important;
        }

        &::before {
            content: '';

            position: absolute;
            top: -8px;
            right: 20px;

            transform: rotate(45deg);

            width: 20px;
            height: 20px;
            border-radius: 6px;

            background-color: white;
        }
    }
    
    &__row {
        display: flex;
        align-items: center;

        margin-top: 20px;
    }

    &__close {
        margin-left: 10px;
        cursor: pointer;
        color: $grey-600;
    }

    &.-active {
        .popup__inner {
            display: block;
        }

    }
}