
.settings {
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 0 10px;
	margin-right: -10px;
	float: right;
	cursor: pointer;

	@include transition;

	&__box {
		position: relative;  
	}

	&__menu {
		position: absolute;
		right: -30px;
		top: 40px;
		z-index: 1;

		display: none;
		width: auto;
		padding: 10px 0;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		white-space: nowrap;
		border-radius: 10px;
		
		background-color: $color-1;
  
		a, span {
			display: block;
			padding: 7px 20px;
			border: none !important;
			
			cursor: pointer;
			font-weight: 400;
			color: white;
	
			&:hover {
				border: none !important;
				color: white!important;
				opacity: .8;
			}
		}
	}

	span {
		display: inline-block;
		width: 4px;
		height: 4px;
		margin: 1.5px;
		border-radius: 5px;
		
		background-color: $color-dark;

		@include media-s {
			width: 3px;
			height: 3px;
		}
	}

	&.active ~ .settings__menu {
		display: block !important;
	}
}
