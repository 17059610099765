
.website-search {
  width: 100%;
  /* height: calc(100vh - 200px); */
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;

  .search_box {
    position: relative;
  }

  .search {
    border: none;
    border-radius: 0;
    height: 60px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
    margin-top: 50px;
    padding: 20px;
    font-size: 18px;
    font-family: $font;

    @media all and (max-width: 680px) {
      width: 100%;
    }

    &::placeholder {
      color: $grey-500;
    }

    &:focus {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    }
  }

  .list {
    width: 100%;
    position: relative;
    background-color: white;
    padding-bottom: 20px;
    max-height: 250px;
    display: none;
    overflow: scroll;
    font-size: 18px;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);

    h6 {
      font-size: 18px;
      border-bottom: 2px solid $grey-500;
      display: block;
      white-space: nowrap;
      font-family: $font;
      margin: 40px 20px 10px 20px;
      color: $grey-500;

      &:nth-child(1) {
        margin-top: 20px;
      }
    }

    label {
      display: block;
      margin-bottom: 0;
      padding: 0 20px;

      input {
        display: inline-block;
        width: auto;
        margin-top: 0;
      }
    }

    p {
      display: block;
      white-space: nowrap;
      font-family: $font;
      margin: 25px 20px 5px 20px;
    }

    a {
      padding: 5px 20px;
      display: block;
      font-family: $font;

      &:hover {
        background: $grey;
      }
    }
  }
}
