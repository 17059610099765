
.project_filter {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;

  @media all and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 70px;
  }

  .filter_error {
    position: absolute;
    bottom: 10px;
    right: 0;
    font-size: 12px;
    color: $red;
  }

  .users {
    display: flex;

    .user {
      background-color: $color-dark;
      color: white;
      margin-right: 10px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      font-family: $font;
      position: relative;
      user-select: none;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      &.disabled {
        background-color: $grey-500;
        box-shadow: none;
        opacity: .4;
      }

      .full_name {
        display: none;
        position: absolute;
        padding: 10px;
        border-radius: 5px;
        top: 60px;
        text-align: center;
        font-size: 15px;
        background-color: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
        min-width: 100px;
        @include transition;
        line-height: 20px;

        &::before {
          content: '\25C0';
          width: 20px;
          height: 20px;
          position: absolute;
          top: -12px;
          margin-right: auto;
          margin-left: auto;
          left: 0;
          right: 0;
          z-index: 2;
          color: white;
          transform: rotate(-26deg);
        }

      }

      &:hover {
        opacity: 1;

        .full_name {
          display: block;
        }
      }
    }
  }

  .date {
    display: flex;

    p {
      position: relative;
      z-index: 1;

      padding: 0 10px;
      margin: 0 10px -6px;
      width: fit-content;
      
      background-color: $color-light;
      color: $grey-500;
      font-size: 12px;
      font-weight: 600;
    }

    input {
      border: none;
      border: 1px solid $grey-500;
      border-radius: 8px;
      padding: 14px 20px;
      background-color: transparent;
      margin-top: 0 !important;
    }

    .submit {
      width: 70px;
      border-bottom: 0;
      font-family: $font;
      margin-bottom: 10px;

    }

    div:nth-child(1) {
      margin-right: 20px;
    }
  }

  & + .overview {
    margin-top: 80px;
  }
}
