.wave {
    display: flex;
    align-items: center;

    &__icon {  
        animation: wave 5s infinite;
        transform-origin: 70% 70%;
        transform: translateY(-5px);
        margin-left: 10px;

        font-size: inherit;
    }
}