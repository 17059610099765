.title-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include media-s {
        flex-direction: column;
        justify-content: flex-start;
    }

    > * {
        margin-top: 0;

        & + * {

            @include media-s {
                margin-top: 30px;
            }
        }
    }

    &.-center {
        align-items: center;

        @include media-s {
            align-items: flex-start;
        }
    }

    &:not(.-less-space) + * {
        margin-top: 60px;
    }

    &.-less-space {
        & + * {
            margin-top: 30px;
        }
    }
}