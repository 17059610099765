.section {

    .container {
        @include space-part;
        @include grid;
        @include container;

        .container {
            @include container('full-width');
        }
    }

    & + .section {
        @include space-standard(margin-top);

        .container {
            
            &::before {
                content: '';
                
                display: block;
                
                width: 100%;
                height: 1px;
                margin-bottom: 40px;

                background-color: $color-2;
            }
        }
    }
} 