.circle {
    position: relative;

    width: 100%;
    justify-content: space-around ;

    &__chart {
        display: block;
        margin: 10px auto;
        max-width: 90%;
        max-height: 250px;

        stroke: $color-2;
    }

    &__bg {
        fill: white;
        stroke: #eee;
        stroke-width: 3.8;
    }
    
    &__circle {
        fill: none;
        stroke-width: 2.8;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }
    
    &__percentage {
        fill: #666;
        font-family: sans-serif;
        font-size: 0.5em;
        text-anchor: middle;

    }

    &__content {
        @include absolute-cover;
        @include flex-center;

        flex-direction: column;
        
        font-size: 24px;
        font-weight: 600;

        span {
            display: block;
        }
    }
}
