form {
    width: 100%;
    max-width: 500px;

    label:not(.radio label) {
        position: relative;
        z-index: 1;
        display: block;

        margin-bottom: -10px;
        margin-left: 15px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5px;
        border-radius: 10px;
        width: fit-content;

        background-color: $color-light;
        
        font-weight: 600;
        font-size: 12px;
        color: $grey-600;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="date"],
    input[type="number"],
    input[type="time"],
    select,
    textarea {
        width: 100%;
        padding: 20px 18px;
        border-radius: 8px;
        border: 1px solid $color-border;
        background-color: white;
        
        & + input {
            margin-top: 15px;
        }
        
        & + label {
            margin-top: 20px;
        }
    }

    select {
        -webkit-appearance: none;
        background-image: url("/svg/arrow_down_black.svg");
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: right 20px center;
    }
    
    textarea {
        height: 150px;
        resize: none;
    }
}

.form {

    &__field {
        & + & {
            margin-top: 15px;
        }

        &.-full {
            .dropdown {
                width: 100%;
            }
        }
    }

    &__selectable {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__subtitle {
        padding-bottom: 10px;
        border-bottom: 1px solid $grey-600;
        color: $grey-600;

        margin: 20px 0;
    }
    
    &__submit {
        display: flex;
        align-items: center;

        margin-top: 40px;

        > p,
        > a {
            transition: $transition;

            color: $color-2;
            margin-left: 20px;
            cursor: pointer;

            &:hover {
                color: $black;
            }
        }
    }
}

// form {
//   width: 400px;
//   font-size: 18px;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;

//   @media all and (max-width: 600px) {
//     width: 100%;
//   }

//   &#dateform {
//     width: 350px;
//   }

//   .form__subtitle {
//     width: 100%;
//     border-bottom: 2px solid $grey-500;
//     margin-top: 50px;
//     color: $grey-500;
//     margin-bottom: 0;
//     font-size: 18px;
//     font-family: $font;

//     &:first-of-type {
//       margin-top: 0;
//     }
//   }

//   input:not(.button), textarea, select {
//     background-color: white;
//     box-shadow: none;
//     width: 100%;
//     padding: 14px 20px;
//     margin-top: 20px;
//     border: none;
//     // border: 1px solid $grey-900;
//     border-radius: 7px;
//     height: auto;
//     font-size: 16px;
//     outline: none!important;

//     &:first-child {
//       margin-top: 0;
//     }

//     &:focus {
//       border-color: $color-1;
//       outline: none;
//     }
//   }

//   label + textarea,
//   label + input {
//     margin-top: 0!important;
//   }

//   select {
//     -webkit-appearance: none;
//     min-height: 55px;
//     border-radius: 7px;
//     background-image: url("/svg/arrow_down_black.svg");
//     background-repeat: no-repeat;
//     background-size: 13px;
//     background-position: right 20px center;
//   }

//   .checkbox {
//     width: 100%;

//     label {
//       display: block;
//       width: 100%;

//       input[type=checkbox] {
//         display: inline-block;
//         width: auto;
//         margin-right: 15px;
//       }
//     }
//   }

//   textarea {
//     resize: none;
//   }

//   .customer {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-top: 20px;
//     width: 100%;

//     p {
//       margin: 0 20px;
//       font-family: $font;
//       color: $grey-500;
//     }

//     .button {
//       padding: 7px 18px;
//     }

//     input[type="radio"]:checked + label {
//       border-left: 4px solid $color-dark;
//     }
//   }

//   .radio {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     margin-top: 5px;
//     height: 30px;
//     margin-bottom: 5px;

//     &.first {
//       margin-top: 20px;
//       margin-bottom: 0;
//     }

//     input {
//       width: 5px !important;
//       position: relative;
//       margin: 0;

//       &::before {
//         content: '';
//         width: 24px;
//         height: 24px;
//         border-radius: 3px;
//         position: absolute;
//         background-color: white;
//       }

//       &::after {
//         content: '';
//         width: 16px;
//         height: 16px;
//         position: absolute;
//         background-color: $color-1;
//         top: 4px;
//         left: 4px;
//         border-radius: 2px;
//         display: none;
//       }
//     }

//     input:checked::after {
//       display: block;
//     }

//     label {
//       width: calc(100% - 25px);
//       margin: 0;
//       margin-top: 8px;
//       margin-left: 30px;
//       display: inline-block;
//       height: 27px;
//     }
//   }

//   .team_form {
//     width: 100%;
//     margin-top: 20px;

//     .tag {
//       display: inline-block;
//       background-color: $color-1;
//       padding: 7px 22px 10px 22px;
//       margin: 10px 0;
//       border-radius: 58px;
//       color: white;
//       box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
//       cursor: pointer;
//       user-select: none;
//       @include transition;

//       &:hover {
//         box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
//       }

//       &.disabled {
//         background-color: $grey-500;
//         box-shadow: none;

//         .cross {
//           transform: rotate(45deg);
//         }
//       }
//     }

//     .cross {
//       font-family: $font;
//       margin-right: 15px;
//       text-shadow: 0 0 1px black;
//       font-size: 15px;
//       cursor: pointer;
//       @include transition(100ms);

//       &.cross-tag {
//         margin-right: 5px;
//         text-shadow: 0 0 1px white;
//       }

//       &.disabled {
//         transform: rotate(45deg);
//       }

//       &.disabled ~ p {
//         color: $grey-500;
//       }
//     }

//     .member_box {
//       display: flex;
//       align-items: center;

//       label {
//         margin-bottom: 0;
//       }
//     }

//     .member {
//       margin: 10px 20px 10px 0;
//       position: relative;

//       input {
//         position: absolute;
//         left: 0;
//         top: 0;
//         width: 100%;
//         height: 100%;
//         opacity: 0;
//         margin: 0;
//       }

//       &.user {
//         width: 35px;
//         height: 35px;
//         box-shadow: none;
//         margin-right: 15px;
//         font-size: 16px;
//         cursor: default;
//         color: white;
//         background-color: $grey-500;
//         opacity: .6;

//         &.checked {
//           opacity: 1;
//         }

//         span {
//           color: white;
//           font-family: $font;
//           font-size: 15px;
//         }
//       }

//       opacity: 1;

//       &.checked {
//         opacity: 1;
//         background-color: $color-dark;
//       }
//     }

//     p {
//       margin: 0;
//     }

//     span {
//       display: inline-block;
//     }
//   }

//   .form__submit {
//     font-family: $font;
//     margin-top: 50px;
//     margin-bottom: 50px;
//     display: flex;
//     align-items: center;

//     .cancel {
//       margin-bottom: 0;
//       margin-left: 20px;
//       color: #C5c5c5;
//       cursor: pointer;
//       transition: 147ms ease-in-out;

//       &:hover {
//         color: black;
//       }
//     }
//   }
// }

// .form__field {

//   label {
//     margin-bottom: 5px;
//   }
//   & + .form__field {
//     margin-top: 15px;
//   }
// }