.toggle-menu {
    display: flex;

    padding: 16px 30px;
    border-radius: 10px;
    border: 1px solid $color-border;
    background-color: white;

    &__item {
        margin-right: 30px;
        color: $grey-500;
        font-size: 14px;
        font-weight: 500;
        
        @include transition;

        &:hover {
            color: $black;
        }

        &.-active {
            color: $black;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
