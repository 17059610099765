.sidebar {
	display: flex;
	justify-content: space-around;

	width: 100%;
	border-radius: 10px;
	padding: 50px;
	margin-top: 50px;

	background-color: white;
	
	text-align: center;

	@media all and (max-width: 1000px) {
		width: 100%;
		margin: 0;
		margin-top: 40px;
	}

	h2 {
		margin: 0;
		font-size: 35px;
	}

	p {
		margin: 0;
		margin-top: 5px;
	}
}